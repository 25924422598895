/* src/Checkout.css */
.checkout {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .checkout h1 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .checkout-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .checkout-item-image {
    max-width: 150px;
    height: auto;
    margin-right: 20px;
  }
  
  .checkout-item-details {
    display: flex;
    flex-direction: column;
  }
  
  .checkout-item-name {
    font-size: 1.5em;
    margin-bottom: 10px;
  }
  
  .checkout-item-price {
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  
  .checkout-item-quantity {
    display: flex;
    align-items: center;
  }
  
  .checkout-item-quantity label {
    margin-right: 10px;
  }
  
  .checkout-button {
    background-color: #000000;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1.2em;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .checkout-button:hover {
    background-color: #4d4d4d;
  }
  