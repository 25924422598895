.itemnav {
  display: flex;
  justify-content: center; /* Center horizontally by default */
}

.itemnav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.itemnav li {
  margin-right: 10px; /* Adjust as needed */
}

.itemnav button {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  color: inherit;
  outline: none;
}

/* Media query for iPhone (adjust as needed for specific screen sizes) */
@media (max-width: 480px) {
  .itemnav {
    justify-content: flex-start; /* Start from the left for iPhone */
    overflow-x: scroll; /* Enable horizontal scrolling */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling for iOS */
    padding-bottom: 8px; /* Add padding for easier scrolling interaction */
  }
}
