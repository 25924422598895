.navbar {
    background-color: #ffffff;
    overflow: hidden;
    display: flex;
    justify-content: center; /* Center the navbar items */
  }
  
  .navbar-list {
    display: flex;
    align-items: center; /* Center items vertically */
    justify-content: space-between; /* Distribute items evenly */
  }
  
  .navbar-item {
    padding: 14px 20px;
    margin-top: 10;
  }
  
  .navbar-item a {
    color: rgb(0, 0, 0);
    text-decoration: none;
    display: block;
    text-align: center;
  }
  
  .navbar-item a:hover {
    background-color: #575757;
  }
  
  .navbar-logo img {
    width: 100px; /* Adjust size as needed */
    cursor: pointer;
  }

  .cart {
    position: fixed;
    left: 90%;
    
  }
  
  .cart-icon {
    
    width: 30px; /* Adjust icon size as needed */
    height: 30px;
    cursor: pointer;
  }

  .logo {
    
    width: 80px; /* Adjust icon size as needed */
    height: 80px;
    cursor: pointer;
  }

  /* src/Navbar.css */

.search-button {
  background: none;
  border: none;
  cursor: pointer;
}

.search-icon {
  width: 32px;
  height: 32px; 
}

.search-form {
  display: flex;
  align-items: center;
  margin-left: 16px;
}

.search-input {
  padding: 4px 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.search-submit-button {
  padding: 4px 8px;
  margin-left: 8px;
  border: none;
  background-color: #000000;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

.timelogo {
  width: 50px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
}