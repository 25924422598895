/* Cart.css */
.cart {

    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    font-size: 2em;
  }
  
  p {
    font-size: 1.2em;
    color: #888;
  }
  