/* RefundPolicy.css */
.container {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  ul {
    list-style-type: disc;
    padding-left: 20px;
    text-align: left;
  }
  
  h1 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  