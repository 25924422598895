/* src/ItemDetail.css */

img {
  height: 10px;
  align-items: center;
}
.item-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .item-detail h1 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .item-detail-image {
    max-width: 100%;
    height: 400px;
    margin-bottom: 20px;
  }
  
  .item-detail p {
    font-size: 1.5em;
    margin-bottom: 20px;
  }
  
  .buy-now-button {
    background-color: #000000;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1.2em;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .buy-now-button:hover {
    background-color: #4d4d4d;
  }
  
  .item {
    align-items: center;
  }