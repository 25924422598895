/* src/ItemGrid.css */

/* Ensure body does not scroll horizontally */
body {
  overflow-x: hidden;
}

.item-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Default: Minimum 200px wide columns */
  gap: 16px;
  justify-content: center;
  padding: 16px;
  margin: 0 auto;
  max-width: 1200px;
}

/* For tablets and smaller devices */
@media (max-width: 1024px) {
  .item-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Adjust for tablets */
  }
}

/* For mobile devices */
@media (max-width: 768px) {
  .item-grid {
    grid-template-columns: repeat(2, 1fr); /* Two items per row for mobile screens */
    gap: 10px; /* Adjust gap as needed */
    padding: 10px; /* Adjust padding as needed */
  }
}

.item-link {
  text-decoration: none;
  color: inherit;
}

.item {
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
  padding: 16px;
  cursor: pointer;
  transition: transform 0.2s;
  display: flex; /* Ensure items are displayed in flex layout */
  flex-direction: column; /* Align content in column */
}

.item:hover {
  transform: scale(1.05);
}

.item-image {
  width: auto; /* Adjust image width to fit container */
  height: auto; /* Maintain aspect ratio */
  max-width: 100%; /* Ensure image doesn't exceed container width */
}

.item-details {
  flex-grow: 1; /* Allow item details to expand within flex container */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.item-name {
  padding: 8px;
  font-size: 1.2em;
  text-align: center; /* Center-align item names */
}

.item-price {
  padding: 8px;
  font-size: 1em;
  color: #888;
  text-align: center; /* Center-align item prices */
}
/* src/ItemGrid.css */

.sort-filter {
  margin: 16px 0;
  text-align: center;
}

.sort-filter label {
  margin-right: 8px;
}

.sort-filter select {
  padding: 4px 8px;
}
