.paypal-container {
    text-align: center;
  }
  
  .page-title {
    font-size: 24px; /* Adjust font size as needed */
    margin-bottom: 20px; /* Optional: Add space between heading and buttons */
  }
  
  .paypal-button-container {
    display: inline-block; /* Ensure container adjusts to content width */
  }

  .pay-img {
    height: 200px;
  }